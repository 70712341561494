import React, {useState, useEffect, useMemo, useRef, Component} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import Logo from './util/logo';

import {API_URL} from "../utils/consts/api";
import http from "../utils/config/http";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

ClassicEditor.defaultConfig = {  
  toolbar: {
			items: [
				'undo', 'redo',
				'|', 'heading',
				'|', 'bold', 'italic',
				'|', 'link', 'insertTable',
				'|', 'bulletedList', 'numberedList', 'outdent', 'indent'
			]
		},
  language: 'en'  
};


const Home = (props) => {
  // const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [home, setHome] = useState({
    title: "",
    text: "<p>Create your post!</p>",
  });
  const [categorias, setCategorias] = useState([]);
  const [posts, setPosts] = useState([]);
  const [myPosts, setMyPosts] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [video, setVideo] = useState("");
  const [user, setUser] = useState(JSON.parse((localStorage.getItem("user"))?localStorage.getItem("user"):"{}"));
  const [categoria, setCategoria] = useState({});
  const [follow, setFollow] = useState([]);
  const [editor, setEditor] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
        
    if(user && user.id){
      http.get(API_URL+'/categorias.php').then((result) => {
        setCategorias(result.data);
        // setCategoria(result.data[0]);
        // openCategory(result.data[0].id);
      }).catch((err) =>
          console.log(err)
        ).finally(() =>
        console.log()
      )

      http.get(API_URL+'/mypost.php?id='+user.id).then((result) => {
        setMyPosts(result.data);
      }).catch((err) =>
          console.log(err)
        ).finally(() =>
        console.log()
      )

      http.get(API_URL+'/follow.php?id='+user.id).then((result) => {
        setFollow(result.data);
      }).catch((err) =>
          console.log(err)
        ).finally(() =>
        console.log()
      )
    }else{
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    console.log(posts);
  }, [posts]);


  function openCategory(category){
    categorias.map((it)=>{
      if(it.id==category){
        setCategoria(it);
      }
    })
    props.closeAll();
    http.get(API_URL+'/post.php?id='+category).then((result) => {
      console.log(result);
      result.data.map((it)=>{
        it.foto=0;
        it.fotos=it.fotos.split(";");
        it.fotos.pop();
      })
      setPosts(result.data);
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }

  function addCategory(category){
    console.log(category);
    let found= false;
    follow.map((it)=>{
      console.log(it.categoria==category.id);
      console.log(it.categoria,category.id);
      if(it.categoria==category.id){
        found= true;
      }
    });
    console.log(found);
    if(!found){
      let data={
        usuario: user.id,
        categoria: category.id
      }
      http.post(API_URL+'/new-follow.php?id='+category.id,data).then((result) => {
        console.log(result.data);
        let newFollow={
          categoria: category.id,
          id: result.data.id,
          nombre: category.nombre
        }
        setFollow([
          ...follow,
          newFollow
        ]);
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }
  }

  function removeCategory(category, ind){
    console.log(category);
    http.get(API_URL+'/remove-follow.php?id='+category.id).then((result) => {
      setFollow(follow.filter((it,i)=>{return i!=ind}));
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }

  function removePost(post, ind){
    console.log(post, ind);
    Swal.fire({
      title: "Are you sure to delete this Post?",
      text: "Once deleted you will not be able to see this Post again.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        http.get(API_URL+'/remove-post.php?id='+post.id).then((result) => {
          setMyPosts(myPosts.filter((it,i)=>{return i!=ind}));
        }).catch((err) =>
          console.log(err)
        ).finally(() =>
          console.log()
        )
      }
    })
  }

  function changeInput(e){
    console.log(e);    
    console.log(home);    
    setHome({
      ...home,
      [e.target.name]: e.target.value
    });
  }

  function deleteFoto(ind){
    setFotos(fotos.filter((it,i)=>{return i!=ind}));
  }

  function verFoto(ind,j){
    console.log(ind,j);
    let newPost=[];
    posts.map((it,i)=>{
      if(i==ind){
        it.foto=j;
      }
      newPost.push(it);
    });
    setPosts(newPost);
  }

  function selectAvatar(e){
    let fileList: FileList = e.target.files;
    if(fileList.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader);
        console.log(file);
        setFotos([
          ...fotos,
          reader["result"]
        ]);
        // addToUpdate("picture");
      };
    }
  }


  function selectVideo(e){      
    let fileList: FileList = e.target.files;
    if(fileList.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader);
        console.log(file);
        setVideo(reader["result"]);
        // addToUpdate("picture");
      };
    }
  }


  function newPost(){
    if(home.title!="" && home.text!=""){
      let data={
        ...home,
        categoria: categoria.id,
        fotos,
        video,
        user: user.id
      };
      console.log(data);
      http.post(API_URL+'/newPost.php', data).then((result) => {
        console.log(result);
        if(result && result.data && result.data.success){
          Swal.fire({
            title: "Post Published.",
            icon: "success"
          });
          let fotos=result.data.fotos.split(";");
          fotos.pop();
          data.fotos=fotos;
          data.video=result.data.video;
          data.foto=0;
          setPosts([
            ...posts,
            data
          ]);
          setHome({
            title: "",
            text: "<p>Create your post!</p>"
          });          
          setFotos([]);
          setVideo("");          
        }else{
          Swal.fire({
            title: "Error, Try again!",
            icon: "warning"
          })
        }
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }else{
      Swal.fire({
        title: "The post must have a title and content.",
        icon: "info"
      });
    }
  }

  return (
    <section className="home">
      <aside className={(props.menu)?"active":""}>
        <ul>
          {
            categorias.map((it,i)=>{
              return(
                <li>
                <p onClick={()=>{openCategory(it.id)}}>
                  {it.nombre}
                </p>
                <figure onClick={()=>{openCategory(it.id);addCategory(it)}}>
                  <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                </figure>
                </li>
              )
            })
          }
        </ul>
      </aside>
      <article>
        <header>
          {(categoria && categoria.id) && <h2>{categoria.nombre} {categoria.nombreEs && <span>/ {categoria.nombreEs}</span>}</h2>}          
        </header>
        {!(categoria && categoria.id) &&
          <aside>
            <header>
              <Logo />
            </header>
            <section>
              <div>
                <h3>Let's go to explain ToBeFriend !!!</h3>
                <p>Now you are here on the WebSite, Now you have to look for your profession or your wish profession to learn. then if you want to share your knowledge, you should select the TOBE respective and create the post with the knowledge and information that you want to share, if you are looking to learn some knowledge, you have to select the TOBE that you are interested in and see the information that people shared.
and that is all, good luck!!</p>
              </div>
              <div>
                <h3>Vamos a explicar ToBeFriend !!!</h3>
                <p>Ahora tu estas aqui adentro del sitio web, debes buscar su profesión o la profesión que deseas aprender. entonces si desea compartir su conocimiento, debe seleccionar el TOBE respectivo y crear la publicación con el conocimiento y la información que deseas compartir, si está buscando aprender algún conocimiento, debes seleccionar el TOBE que le interesa y ver la información que la gente compartió.
eso es todo, mucha suerte!!</p>
              </div>
            </section>
            <footer>
              <p >tobefriend.net</p>
            </footer>
          </aside>
        }
        {(categoria && categoria.id) &&
          <section>
            <div>
              <input type="text" placeholder="title" value={home.title} name="title" onChange={changeInput}/>
            </div>
            <div>              
              <CKEditor
                editor={ ClassicEditor }                     
                data={home.text}
                onReady={ editor2 => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                    setEditor(editor2);
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    console.log( { event, editor, data } );                    
                    if(data=="<p>Create your post!</p>"){
                      setHome({                        
                        title: "",
                        text: data
                      });
                    }else{
                      setHome({
                        ...home,
                        text: data
                      });
                    }
                } }
                onBlur={ ( event, editor ) => {                    
                } }
                onFocus={ ( event, editor ) => {                    
                } }
            />
            </div>
            <section>
              <aside>
                <ul>
                  {
                    fotos.map((jt,j)=>{
                      return(
                        <li>
                          <header>
                            <figure onClick={()=>{deleteFoto(j)}}>
                              X
                            </figure>
                          </header>
                          <figure>
                            <img src={jt}/>
                          </figure>
                        </li>
                      )
                    })
                  }
                </ul>
              </aside>
              {(video!="") && <div>
                <figure>
                  <video controls >
                    <source src={video} type="video/mp4"/>
                  </video>
                </figure>
              </div>}
            </section>
            <article>
              <div>
                <input type="file" name="file" onChange={selectVideo} />
                <figure>
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 23h-24v-21h24v21zm-20-1v-4h-3v4h3zm15 0v-19h-14v19h14zm4 0v-4h-3v4h3zm-6-9.5l-9 5v-10l9 5zm3 .5v4h3v-4h-3zm-16 4v-4h-3v4h3zm5-1.2l5.941-3.3-5.941-3.3v6.6zm11-7.8v4h3v-4h-3zm-16 4v-4h-3v4h3zm16-9v4h3v-4h-3zm-16 4v-4h-3v4h3z"/></svg>
                </figure>
                <p>Add Video(s)</p>
              </div>
              <div>
                <input type="file" name="file" onChange={selectAvatar} />
                <figure>
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-1 16h-19l4-7.492 3 3.048 5.013-7.556 6.987 12zm-11.848-2.865l-2.91-2.956-2.574 4.821h15.593l-5.303-9.108-4.806 7.243zm-4.652-11.135c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm0 1c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>
                </figure>
                <p>Add Photo(s)</p>
              </div>
            </article>
            <footer>
              <button type="button" class="btn2" name="button" onClick={newPost}>post</button>
            </footer>
          </section>
        }
        {
          posts.map((it,i)=>{
            return(
              <article>
                <h4>{it.title}</h4>
                <h5>{it.creado}</h5>
                <div dangerouslySetInnerHTML={{ __html: it.text }}></div>                
                <div>
                  <h5>Photos</h5>
                  {it.fotos.length>0 && 
                    <figure>
                      <img src={API_URL+"/fotos/"+it.fotos[it.foto]}/>
                    </figure>
                  }
                  {it.fotos.length>0 && 
                    <article>
                      <ul>
                        {
                          it.fotos.map((jt,j)=>{
                            return(
                              <li>
                                <figure onClick={()=>{verFoto(i,j)}}>
                                  <img src={API_URL+"/fotos/"+jt}/>
                                </figure>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </article>
                  }
                </div>

                <footer>
                {(it.video!="") &&
                  <h5>Video</h5>}
                  {(it.video!="") &&
                  <figure>
                    <video controls >
                      <source src={API_URL+"/videos/"+it.video} type="video/mp4"/>
                    </video>
                  </figure>}
                  <p>Shared by {it.nombre}</p>
                </footer>
              </article>
              )
            })
          }
      </article>
      <article className={(props.love)?"active":""}>
        <h3>My Tobe</h3>
        <ul>
          {
            follow.map((it,i)=>{
              return(
                <li key={it.id}>
                  <p onClick={()=>{openCategory(it.categoria)}}>
                    {it.nombre}
                  </p>
                  <figure onClick={()=>{removeCategory(it,i)}}>
                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" fill-rule="nonzero"/></svg>
                  </figure>
                </li>
              )
            })
          }
        </ul>
        <section>
          <h4>My Shared</h4>
          <ul>
            {
              myPosts.map((it,i)=>{
                return(
                  <li>
                    <p>
                      Post to {it.nombre} {it.creado}
                    </p>
                    <figure onClick={()=>{removePost(it,i)}}>
                      <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" fill-rule="nonzero"/></svg>
                    </figure>
                  </li>
                )
              })
            }
          </ul>
        </section>
      </article>
    </section>
);
}

export default Home;
