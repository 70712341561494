import axios from 'axios';

const http = axios.create();

/**
 * Interceptor for all requests
 */
http.interceptors.request.use((config) => {
    console.log(config);
    /**
     * Add your request interceptor logic here: setting headers, authorization etc.
     */
     // config.async= true;
     // config.crossDomain= true;
     // config.type= "GET";
     // config.dataType= "json";
     // config.contentType= "application/json";
     // config.async= true;
     // config.crossDomain= true;
     // // config.type= "GET";
     // config.dataType= "json";
     // config.contentType= "application/json";
     config.headers={
       'Content-Type': "application/json",
       'Accept': "application/json"       
     }
    return config;
}, (error) => {

    /**
     * Add your error handlers here
     */

    return Promise.reject(error);
});

/**
 * Interceptor for all responces
 */
http.interceptors.response.use((response) => {

    /**
     * Add logic for successful response
     */

    return response;
}, (error) => {

    /**
     * Add logic for any error from backend
     */

    return Promise.reject(error);
});

export default http;
