import React, {useEffect, useState} from 'react';

import { BrowserRouter as Router, HashRouter, Route, Link, Routes, useLocation } from 'react-router-dom';

import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';
import NewPost from './pages/new-post';
import Home from './pages/home';

import Nav from './pages/util/nav';
import './styles/App.scss';

import { UserProvider } from './UserProvider';
import { DataProvider } from './DataProvider';

function App() {

  const [menu, setMenu] = useState(false);
  const [love, setLove] = useState(false);


  function openMenu(){
    setMenu(!menu);
    setLove(false);
  }
  function openLove(){
    setMenu(false);
    setLove(!love);
  }
  function closeAll(){
    setMenu(false);
    setLove(false);
  }

  return (
    <DataProvider>
      <UserProvider>
        <main className="App">
          <Router basename={"/"}>
            <Nav openMenu={openMenu} openLove={openLove} />
            <Routes>

              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />

              <Route exact path="/" element={<Home closeAll={closeAll} menu={menu} love={love} />} />
              <Route path="/home" element={<Home closeAll={closeAll} menu={menu} love={love} />} />
              <Route path="/new-post" element={<Home />} />
            </Routes>
          </Router  >
        </main>
      </UserProvider>
    </DataProvider>
  );
}

export default App;
