import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import Logo from './util/logo';
import { Link, useNavigate } from 'react-router-dom';

import http from "../utils/config/http";
import {API_URL} from "../utils/consts/api";

const Profile = (props) => {

  // const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const [user, setUser] = useState(JSON.parse((localStorage.getItem("user"))?localStorage.getItem("user"):"{}"));
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
    if(user && user.nombre){
    }else{
      console.log(user);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  function selectAvatar(e){
    let fileList: FileList = e.target.files;
    if(fileList.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader);
        console.log(file);
        let type=file.name.split(".");
        // setFoto(reader["result"]);
        let data={
          foto: reader["result"],
          id: user.id
        }
        http.post(API_URL+'/update-user.php',data).then((result) => {
          console.log(result);
          localStorage.setItem("user",JSON.stringify({
            ...user,
            foto: result.data.file
          }));          
          window.location.reload();
        }).catch((err) =>
          console.log(err)
        ).finally(() =>
          console.log()
        )
      };
    }
  }

  function changeInput(e){
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  }



  function save(){
    let data={
      nombre: user.nombre,
      contrasena: user.contrasena,
      id: user.id
    }
    http.post(API_URL+'/update-user.php',data).then((result) => {
      console.log(result);
      let user2={
        ...user,
        nombre: user.nombre,
        contrasena: user.contrasena
      }
      localStorage.setItem("user",JSON.stringify(user2));
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }

  function cerrarSesion(){
    localStorage.setItem("user",null);
    navigate("/login");
  }


  return (
    <section className="profile">
      <header>
        <h3>Profile</h3>
        <h4 onClick={cerrarSesion}>SIGN OFF</h4>
      </header>
      <article>
        <aside>
          <header>
            <Logo />
          </header>
          <article>
            <h3>Register and learn from </h3>
            <h3>the people shared knowledge, </h3>
            <h3>And get free to share what your know as well.</h3>
          </article>
        </aside>
        <section>
          {user && <form>
            <header>
              <div>{user.nombre}</div>
              <figure>
                <input type="file" name="file" onChange={selectAvatar} />
                {user && (user.foto == undefined || user.foto == null) && <img src={API_URL+"/profiles/default.png"} alt=""/>}
                {user && user.foto != null && user.foto != undefined && user.foto != ""  && <img src={API_URL+"/profiles/"+user.foto} alt=""/>}
              </figure>
            </header>
            <div>
              <label for="">Name</label>
              <input type="text" placeholder="Name" value={user.nombre} name="nombre" onChange={changeInput}/>
            </div>
            <div>
              <label for="">Email :</label>
              <input type="text" placeholder="Email" value={user.email} name="email" onChange={changeInput} disabled/>
            </div>
            <div>
              <label for="">Password :</label>
              <input type="password" placeholder="Password" value={user.password} name="password" onChange={changeInput}/>
            </div>
            {false && <div>
              <label for="">Gender :</label>
              <div>
                <label for="">Male</label>
                <input type="radio" name="gener" value=""/>
              </div>
              <div>
                <label for="">Female</label>
                <input type="radio" name="gener" value=""/>
              </div>
            </div>}
            <footer>
              <button type="button" class="btn2" onClick={save} name="button">Update</button>
            </footer>
          </form>}
        </section>
      </article>
    </section>
);
}

export default Profile;
