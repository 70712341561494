import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import Logo from './util/logo';
import http from "../utils/config/http";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import {API_URL} from "../utils/consts/api";

import Swal from 'sweetalert2'

const Register = (props) => {

  // const dispatch = useDispatch();
  const [register, setRegister] = useState({
    name: "",
    email: "",
    password: ""
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useMemo(() => {
  }, []);


  function registrar(){
    if(!(register.name=="" || register.email =="" || register.password=="")) {
      console.log(register);
      console.log(JSON.stringify(register));
      http.post(API_URL+'/registrar.php', JSON.stringify(register)).then((result) => {
        console.log(result);
        if(result && result.data && result.data.success){
          Swal.fire({
            title: "user registered successfully.",
            icon: "success"
          }).then(() => {
            navigate("/login");
          })
        }else{
          Swal.fire({
            title: result.data.message,
            icon: "info"
          }).then(() => {            
          })
        }
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }else{
      Swal.fire({
        title: "The user must have a name, email and password.",
        icon: "info"
      });
    }
  }

  function changeInput(e){
    setRegister({
      ...register,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="register">
      <header>
      </header>
      <article>
        <aside>
          <header>
            <Logo />
          </header>
          <article>
            <h3>Register and learn from </h3>
            <h3>the people shared knowledge, </h3>
            <h3>And get free to share what your know as well.</h3>
          </article>
        </aside>
        <section>
          <form>
            <header>
              <div><span onClick={()=>{navigate("/login");}}>Login</span> / <span>Register</span></div>
            </header>
            <div>
              <input type="text" placeholder="Name" value={register.name} name="name" onChange={changeInput}/>
            </div>
            <div>
              <input type="text" placeholder="Email" value={register.email} name="email" onChange={changeInput}/>
            </div>
            <div>
              <input type="password" placeholder="Password" value={register.password} name="password" onChange={changeInput}/>
            </div>
            <footer>
              <button type="button" class="btn1" name="button" onClick={registrar}>Register</button>
            </footer>
          </form>
        </section>
      </article>
    </section>
);
}

export default Register;
