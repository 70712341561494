import React, {useState, useEffect, useMemo, useRef} from 'react';

import { Link, useNavigate } from 'react-router-dom';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import Logo from './util/logo';
import http from "../utils/config/http";
import Swal from 'sweetalert2'
import {API_URL} from "../utils/consts/api";

const Login = (props) => {

  // const dispatch = useDispatch();
  const [login, setLogin] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useMemo(() => {

  }, []);

  function login2(){
    console.log(login);
    console.log(JSON.stringify(login));
    http.post(API_URL+'/login.php', JSON.stringify(login)).then((result) => {
      console.log(result);
      if(result && result.data && result.data.success){
        let user={
          ...result.data.data,
          ...login
        }
        localStorage.setItem("user",JSON.stringify(user));
        navigate("/home");
      }else{
        Swal.fire({
          title: "Wrong username or password.",
          icon: "warning"
        })
      }
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }

  function changeInput(e){
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="login">
      <header>
      </header>
      <article>
        <aside>
          <header>
            <Logo />
          </header>
          <article>
            <h3>Register and learn from </h3>
            <h3>the people shared knowledge, </h3>
            <h3>And get free to share what your know as well.</h3>
          </article>
        </aside>
        <section>
          <form>
            <header>
              <div><span>Login</span> / <span onClick={()=>{navigate("/register");}}>Register</span></div>
            </header>
            <div>
              <input type="text" placeholder="Email" value={login.email} name="email" onChange={changeInput}/>
            </div>
            <div>
              <input type="password" placeholder="Password" name="password" value={login.password} onChange={changeInput}/>
            </div>
            <footer>
              <button type="button" class="btn1" name="button" onClick={login2}>Login</button>
            </footer>
          </form>
        </section>
      </article>
    </section>
);
}

export default Login;
