import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Route, Link, Routes, Redirect, withRouter, useLocation, useNavigate} from 'react-router-dom';
import Logo from './logo';
import {API_URL} from "../../utils/consts/api";

const Nav = (props) => {

  // const dispatch = useDispatch();
  const [nav, setNav] = useState(props);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [user, setUser] = useState(JSON.parse((localStorage.getItem("user"))?localStorage.getItem("user"):"{}"));

  useEffect(() => {
    // dispatch(getDesigns(1,2));
    console.log(user);
    if(user && user.nombre){
    }else{
      navigate("/login");
    }
    console.log(props);
  }, []);

  useEffect(() => {
    setNav(props);
    // setEditorHtml(props.editor)
  },[props.name]);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    // setEditorHtml(props.editor)
  },[location]);

  function changeInput(e){
    setNav({
      ...nav,
      [e.target.name]: e.target.value
    });
  }


  return (
    <>
      {(path!="/login" && path!="/register" && path!="/profile") &&
        <nav>
          <div>
            <div className="menu" onClick={props.openMenu}>
              <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22 16.75c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>
            </div>
            <Logo />
          </div>
          <div>
            <div className="love" onClick={props.openLove}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.5 10c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-6.527 4.593c-1.108 1.086-2.275 2.219-3.473 3.407-6.43-6.381-12-11.147-12-15.808 0-4.005 3.098-6.192 6.281-6.192 2.197 0 4.434 1.042 5.719 3.248 1.279-2.195 3.521-3.238 5.726-3.238 3.177 0 6.274 2.171 6.274 6.182 0 .746-.156 1.496-.423 2.253-.527-.427-1.124-.768-1.769-1.014.122-.425.192-.839.192-1.239 0-2.873-2.216-4.182-4.274-4.182-3.257 0-4.976 3.475-5.726 5.021-.747-1.54-2.484-5.03-5.72-5.031-2.315-.001-4.28 1.516-4.28 4.192 0 3.442 4.742 7.85 10 13l2.109-2.064c.376.557.839 1.048 1.364 1.465z"/></svg>
            </div>
            {user && (user.foto == undefined || user.foto == null) && <div >
              <figure onClick={()=>{navigate("/profile");}}>
                {user.nombre.substring(0,1)}
              </figure>
            </div>}
            {user && user.foto != null && user.foto != undefined && user.foto != ""  && <div >
              <figure onClick={()=>{navigate("/profile");}}>
                <img src={API_URL+"/profiles/"+user.foto} alt=""/>
              </figure>
            </div>}
          </div>
        </nav>
      }
    </>
);
}

export default Nav;
