import React from 'react';
import logoImage from "../../assets/img/tobefriend.png";
import iconImage from "../../assets/img/icon.png";
import { Link, useNavigate } from 'react-router-dom';


const Logo = () => {
  const navigate = useNavigate();

  return (
    <section className="logo" onClick={()=>{navigate("/");}}>
      <figure>
        <img src={logoImage}/>
      </figure>
      <div>
        <h1>ToBeFriend</h1>
        <h3>Share your Knowledge</h3>
        <figure>
          <img src={iconImage}/>
        </figure>
      </div>
    </section>
);
}

export default Logo;
